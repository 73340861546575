import PropTypes from "prop-types";
import IconArrowLeft from "app/pages/.shared/static/icons/IconArrowLeft";
import IconArrowRight from "app/pages/.shared/static/icons/iconArrowRight";
import "./CarouselArrow.scss";
import { memo } from "react";

export const ARROW_DIRECTION = {
	LEFT: "left",
	RIGHT: "right",
};

const CarouselArrow = ({
	direction = ARROW_DIRECTION.LEFT,
	hidden = false,
	onClick = () => {},
}) => {
	return (
		!hidden && (
			<div className="carousel-arrow" onClick={onClick}>
				{direction === ARROW_DIRECTION.LEFT ? <IconArrowLeft /> : <IconArrowRight />}
			</div>
		)
	);
};

CarouselArrow.propTypes = {
	direction: PropTypes.oneOf(["left", "right"]),
	hidden: PropTypes.bool,
	onClick: PropTypes.func,
};

export default memo(CarouselArrow);
