import { connect } from "react-redux";
import PropTypes from "prop-types";
import IconAccount from "app/pages/.shared/IconAccount/IconAccount";
import IconMenu from "app/pages/.shared/static/icons/IconMenu";
import { isAuthenticated } from "app/pages/Auth/authSelectors";
import { resolutionPropType } from "app/utils/propTypes";
import { BRANDS, RESOLUTION } from "app/constants";
import { FormattedMessage } from "react-intl";
import { brandPropTypes } from "app/utils/propTypes";
import "./HamburgerAccount.scss";

const HamburgerAccount = ({ resolution, onToogle, userIsConnected = false, brand }) => {
	const isBrandPS = brand === BRANDS.PS;
	const isMobile = resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM;
	let hamburgerLabel = "";
	if (isBrandPS || isMobile) {
		hamburgerLabel = "header.nav.item.menu";
	} else if (userIsConnected) {
		hamburgerLabel = "header.nav.item.my.account";
	} else {
		hamburgerLabel = "header.login";
	}
	return (
		<div className="hamburger-account" data-testid="hamburger-account" onClick={onToogle}>
			{isMobile ? <IconMenu /> : <IconAccount />}

			<div className="hamburger-account__label">
				<FormattedMessage id={hamburgerLabel} />
			</div>
		</div>
	);
};

HamburgerAccount.propTypes = {
	userIsConnected: PropTypes.bool,
	resolution: resolutionPropType,
	onToogle: PropTypes.func,
	brand: brandPropTypes,
};

const mapStateToProps = state => ({
	userIsConnected: isAuthenticated(state),
	resolution: state.resolution,
	brand: state.brand.code,
});

export default connect(mapStateToProps)(HamburgerAccount);
